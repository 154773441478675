import auth from "./entities/auth";
import analyses from "./entities/analyses";
import chat from "./entities/chat";
import vox from "./entities/vox";
import call from "./entities/call";
import config from "./entities/config";
import drugs from "./entities/drugs";
import diagnoses from "./entities/diagnoses";
import chatroom from "./entities/chatroom";
import payment from "./entities/payment";
import fullSchedule from "./entities/fullSchedule";
import schedule from "./entities/schedule";
import scheduleInserts from "./entities/scheduleInserts";
import services from "./entities/services";
import shortcuts from "./entities/shortcuts";
import messageAttachments from "./entities/messageAttachments";
import medicines from "./entities/medicines";
import examinations from "./entities/examinations";
import documents from "./entities/documents";
import appointments from "./entities/appointments";
import doctors from "./entities/doctors";
import embryos from "./entities/embryos";
import medicalFiles from "./entities/medicalFiles";
import medicalFilesV2 from "./entities/medicalFilesV2";
import medicalGenomics from "./entities/medicalGenomics";
import notices from "./entities/notices";
import notifications from "./entities/notifications";
import review from "./entities/review";
import reviewStatistics from "./entities/reviewStatistics";
import organizations from "./entities/organizations";
import ticket from "./entities/ticket";
import concierge from "./entities/concierge";
import stories from "./entities/stories";
import metric from "./entities/metric";
import feedback from "./entities/feedback";
import files from "./entities/files";
import uploads from "./entities/uploads";
import questionnaire from "./entities/questionnaire";
import hospitalAdmissions from "./entities/hospitalAdmissions";
import oldAuth from "./entities/oldAuth";
import survey from "./entities/survey";
import surveyResult from "./entities/surveyResult";
import communication from "./entities/communication";
import integrations from "./entities/integrations";
import prescribedServices from "./entities/prescribedServices";
import voxPatientConsent from "./entities/voxPatientConsent";
import dmsServices from "./entities/dmsServices";
import newGeneralSchedule from "./entities/newGeneralSchedule";
import VRTReports from "./entities/VRTReports";

export default {
  auth,
  analyses,
  chat,
  vox,
  call,
  config,
  drugs,
  diagnoses,
  chatroom,
  payment,
  fullSchedule,
  schedule,
  scheduleInserts,
  services,
  shortcuts,
  messageAttachments,
  medicines,
  examinations,
  documents,
  appointments,
  doctors,
  embryos,
  medicalFiles,
  medicalFilesV2,
  medicalGenomics,
  notices,
  notifications,
  review,
  reviewStatistics,
  organizations,
  ticket,
  concierge,
  stories,
  metric,
  feedback,
  files,
  uploads,
  questionnaire,
  hospitalAdmissions,
  oldAuth,
  survey,
  surveyResult,
  integrations,
  communication,
  prescribedServices,
  VRTReports,
  voxPatientConsent,
  dmsServices,
  newGeneralSchedule
};
